
import Vue from 'vue' 
import Router from "vue-router";
import Home from "@/components/Home.vue";
import OrderSearch from "@/components/OrderSearch.vue";


Vue.use(Router);
export default new Router({
  mode: 'hash',  // 使用 HTML5 History 模式
  routes: [
    {
      path: "/:order_id?",
      name: "home",
      component: Home,
    },
    {
      path: "/OrderSearch/:order_id?",
      name: "OrderSearch",
      component: OrderSearch,
    },
    // 可以继续添加更多路由...
  ],
});
