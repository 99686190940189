<template>
  <div style="padding: 0 10px">
    
    <div style="height: 75px; width: 100%">
      <div style="position: fixed; top: 0; left: 0px; z-index: 999; right: 0px">
        <HeaderComponent></HeaderComponent>
      </div>
    </div>
    <!-- <button @click="handleGetTwoFa('WSIOXX457CZVAT6U')">获取2fa</button> -->
    <div class="title">
      
      商家订单号
      <span @click="handleGetOrderNumber">(获取历史提交订单号)</span>
    </div>
    <div style="margin: 10px 0">
      <el-input
        v-model.trim="orderNumbere"
        placeholder="请输入商家订单号"
      ></el-input>
    </div>
    <div>
      <el-button style="width: 40%" round @click="$router.push('/')"
        >返回</el-button
      >

      <el-button @click="handleScarch" style="width: 40%" type="primary" round
        >搜索</el-button
      >
      
    </div>
    
    <div class="footer">订单查询</div>
    <template v-if="accountInfoList.length > 0">
      <div>
        <div style="color: red">订单信息</div>
        <h3>商家订单号</h3>
        <span>{{ copyNum }}</span>
        <h2 style="color: green">账号信息</h2>
      </div>
      <div
        class="account_info"
        v-for="(item, index) in accountInfoList"
        :key="index"
      >
        <h3 style="color: orange">账户{{ index + 1 }}</h3>
        <h4>账户类型</h4>
        <span>{{goodsName}}</span>
        <h4>用户名</h4>
        <span>{{ item.account_no }}</span>
        <el-button
          size="mini"
          type="primary"
          @click="handleCopyInfot(item.account_no)"
          >复制</el-button
        >

        <template v-if="item.other && goodsType == 5">
          <el-button size="mini" @click="handleDownLoad(item.other)">下载</el-button>
          <el-button size="mini" @click="resolveTgCode(item.other)">验证码</el-button>
        </template>
        
        <template v-if="item.account_pwd">
          <h4>密码</h4>
          <span>{{ item.account_pwd }}</span>
          <el-button
            size="mini"
            type="primary"
            @click="handleCopyInfot(item.account_pwd)"
            >复制</el-button
          >
        </template>

        <template v-if="item.email">
          <h4>邮箱</h4>
          <span>{{ item.email }}</span>
          <el-button
            size="mini"
            type="primary"
            @click="handleCopyInfot(item.email)"
            >复制</el-button
          >
          <h4>邮箱密码</h4>
          <span>{{ item.email_pwd }}</span>
          <el-button
            size="mini"
            type="primary"
            @click="handleCopyInfot(item.email_pwd)"
            >复制</el-button
          >
        </template>

        <div style="margin: 5px 0" v-if="item.email&&item.email_pwd">
          <el-button type="success" @click="handleGetTwoFa(item.verify)">
            获取2fa验证码</el-button
          >
          <div style="color: red">登录出现二次验证，点击2fa验证获取</div>
        </div>
      </div>
    </template>
    <el-dialog
      :visible.sync="dialogVisible"
      width="90%"
      :before-close="handleClose"
      title="历史订单号"
    >
      <!-- <div class="img">
        <img src="@/assets/img/icon-order.jpg" alt="" />
      </div> -->
      <div>
        <ul style="max-height: 60vh; overflow: auto">
          <li
            class="order_li"
            v-for="(item, index) in historyOrderList"
            :key="index"
          >
            <div>
              <span>{{ item.orderNum }}</span>
              <el-button
                size="mini"
                type="primary"
                style="margin-left: 5px"
                @click="handleCopyInfot(item.orderNum)"
                >复制</el-button
              >
            </div>
            <div style="font-size: 14px; color: #ccc">
              提交时间: {{ item.time }}
            </div>
          </li>
        </ul>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="dialogVisibleTwofa"
      width="90%"
      :before-close="handleCloseTwo2fa"
    >
      <div class="towFa">
        <h2 style="margin-bottom: 15px">一次性验证码</h2>
        <div>{{ twoFaValue }}</div>
        <el-button
          @click="handleGetTwoFa(copyTwoFa)"
          style="margin-top: 10px"
          size="mini"
          type="primary"
          >刷新</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import axios from "axios";
import HeaderComponent from "../components/HeaderComponent.vue";
import { authenticator } from "otplib";
// import * as speakeasy from "speakeasy";

export default {
  components: {
    HeaderComponent,
  },
  data() {
    return {
      copyTwoFa: "",
      historyOrderList: [],
      timeIndex: 1,
      timer: null,
      twoFaValue: "",
      dialogVisibleTwofa: false,
      copyNum: "",
      accountInfoList: [],
      orderNumbere: "",
      dialogVisible: false,

      goodsName: "",
      goodsType: -1,
    };
  },
  mounted() {
    console.log("this.", this.$route);
    const id = this.$route.query.order_id;
    if (id) {
      this.copyNum = id;
      this.orderNumbere = id;
      // this.handleOrderInquiry(id);
      this.handleTimeFun(id);
      setTimeout(() => {
        this.handleCopyID(id);
      }, 1000);
    }
  },
  methods: {
    handleDownLoad(value) {
      window.open(`/api/telegram-direct-auth/download?orderId=${this.orderNumbere}&file=${value}`);
    },

    resolveTgCode(fileName) {
      axios.get("/v1/goods/get_tg_code", {
        params: {
          file_name: fileName,
          order_id: this.orderNumbere
        }
      }).then(res => {
        if (res.data.code === 200) {
          this.$confirm(`验证码: ${res.data.data.code} 密码: ${res.data.data.password}`, '提示', {
            type: 'success',
          })
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    handleTimeFun(id) {
      clearInterval(this.timer);
      this.timer = setInterval(() => {
        console.log("定时器", this.timeIndex);
        this.timeIndex++;
        axios
          .get("/v1/orders/order_pay_status", { params: { order_id: id } })
          .then((result) => {
            const { code, success, data } = result.data;
            if (code == 200 && success) {
              if (data.status) {
                clearInterval(this.timer);
                this.handleScarch();
                //  this.$router.push({ name: 'OrderSearch', query: { order_id: id } });
              }
            }
            if (this.timeIndex > 500) {
              clearInterval(this.timer);
            }
          });
      }, 1500);
    },
    handleCloseTwo2fa() {
      this.dialogVisibleTwofa = false;
    },
    // handleGetTwoFa(value) {
    //   console.log("value", value);
    //   if (!value)
    //     return this.$message.warning("该账户没有2fa验证器验证，请选择邮箱验证");
    //   let str = value.replace("2fa/", "");
    //   if (!str)
    //     return this.$message.warning("该账户没有2fa验证器验证，请选择邮箱验证");
    //   this.dialogVisibleTwofa = true;
    //   console.log("sta", str);
    //   this.copyTwoFa = str;
    //   const secret = str;
    //   // 生成一次性密码
    //   const token = speakeasy.totp({ secret });
    //   console.log("一次性密码:", token);
    //   this.twoFaValue = token;
    // },
    handleGetTwoFa(value) {
      if (!value) {
        return this.$message.warning("该账户没有2fa验证器验证，请选择邮箱验证");
      }
      const cleanedValue = value.startsWith("2fa/") ? value.slice(4) : value;
      this.dialogVisibleTwofa = true;
      this.copyTwoFa = cleanedValue;
      try {
        const token = authenticator.generate(cleanedValue);
        this.twoFaValue = token;
      } catch (error) {
        this.$message.error("验证码获取错误，请联系客服");
      }
    },
    // 订单查询
    handleOrderInquiry(id) {
      if (!id) return this.$message.warning("请输入订单号");
      axios
        .get(
          "/v1/orders/order_pay",
          { params: { order_id: id } },
          {
            "Content-Type": "application/json",
          }
        )
        .then(async (result) => {
          const { code, data, msg, success } = result.data;
          if ((code == 200) & success) {
            const { goods_type, name, items } = data;
            
            this.goodsName = name;
            this.goodsType = goods_type;
            this.accountInfoList = items;
            this.copyNum = id;
            console.log("订单详情页", data);
          } else {
            this.$message.error(msg || data);
          }
        });
    },
    handleCopyInfot(val) {
      const textarea = document.createElement("textarea");
      textarea.value = val; // 获取span中的文本
      document.body.appendChild(textarea);
      textarea.select();
      textarea.setSelectionRange(0, textarea.value.length); // 对于移动设备
      try {
        const successful = document.execCommand("copy");
        if (successful) {
          this.$message.success("已复制"); // 使用 Element UI 的提示
        } else {
          this.$message.error("复制失败");
        }
      } catch (err) {
        this.$message.error("复制失败: " + err);
      }
      // 移除临时的textarea元素
      document.body.removeChild(textarea);
    },
    handleCopyID(val) {
      const textarea = document.createElement("textarea");
      textarea.value = val; // 获取span中的文本
      document.body.appendChild(textarea);
      textarea.select();
      textarea.setSelectionRange(0, textarea.value.length); // 对于移动设备
      try {
        const successful = document.execCommand("copy");
        if (successful) {
          console.log("已复制");
          // this.$message.success("已复制"); // 使用 Element UI 的提示
        } else {
          console.log("复制失败");
          // this.$message.error("复制失败");
        }
      } catch (err) {
        console.log("复制失败");
        // this.$message.error("复制失败: " + err);
      }
      // 移除临时的textarea元素
      document.body.removeChild(textarea);
    },
    handleScarch() {
      this.handleOrderInquiry(this.orderNumbere);
    },
    handleClose() {
      this.dialogVisible = false;
    },
    handleGetOrderNumber() {
      let value = localStorage.getItem("oderIdList");
      if (!value) return this.$message.error("暂无查询到订单");
      this.historyOrderList = JSON.parse(value);
      this.dialogVisible = true;
    },
  },
  beforeDestroy() {
    // 在组件销毁之前清除定时器
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
};
</script>

<style scoped lang="scss">
.order_li {
  padding: 5px 0;
  border-bottom: 1px solid rgba(116, 107, 107, 0.8);
  margin-bottom: 10px;
}
.towFa {
  font-weight: 600;
  div {
    margin: 0 auto;
    border: 1px solid #b4a6a6;
    width: 100px;
    height: 40px;
    text-align: center;
    line-height: 40px;
  }
}
.messageClass {
  max-width: 200px !important;
}
.title {
  font-weight: 500;
  margin: 10px;
  span {
    color: red;
  }
}
.img {
  img {
    width: 100%; /* 或具体宽度 */
    height: auto; /* 保持高度自动，以维护宽高比 */
    object-fit: cover; /* 缩放图片以覆盖整个容器 */
  }
}
.footer {
  width: 100%;
  height: 60px;
  line-height: 60px;
  text-align: left;
  border-radius: 5px;
  margin-top: 10px;
  padding-left: 20px;
  background-color: #d7e0f4;
}
.messageClass {
  width: 200px !important;
  background-color: green !important;
}
.account_info {
  // text-align: left;
  h2 {
    margin: 5px 0;
    color: rgb(36, 202, 30);
  }
  h3 {
    margin: 5px 0;
    margin-top: 10px;
  }
  h4 {
    margin: 5px 0;
  }
  span {
    margin-right: 5px;
    color: #ccc;
  }
}
::v-deep .el-dialog__body {
  padding-top: 0px;
}
</style>
