<template>
  <div style="text-align: left; padding: 0 5px">
    <div style="height: 75px; width: 100%">
      <div style="position: fixed; top: 0; left: 0px; z-index: 99; right: 0px">
        <HeaderComponent></HeaderComponent>
      </div>
    </div>
    <!-- <div @click="handleClick">点击</div> -->
    <!-- <div class="warning_sty">
     
    </div> -->
    <div class="scrolling-wrapper">
      <!-- <div class="scrolling-text warning_sty">
        <i class="el-icon-warning"></i>
        <span style="margin-left: 5px"
          >本站无须注册即可下单，付款后通过订单详情里查看账号（包含账号密码），在订单查询里可查询到订单号信息查看！本站专业提供高质量各种ID，安全保障、找回包赔、不能用包换，24小时付款自动发货。本站所有商品均为空白身份注册，不包含公民隐私信息！本站不向未成年人提供任何服务，如您未成年，请立即关闭网站。</span
        >
      </div> -->
      <div class="scroll-container warning_sty">
        <div class="scroll-text">
          <i class="el-icon-warning"></i>
          <span style="margin-left: 5px"
            >本站无须注册即可下单，付款后通过订单详情里查看账号（包含账号密码），在订单查询里可查询到订单号信息查看！本站专业提供高质量各种ID，安全保障、找回包赔、不能用包换，24小时付款自动发货。本站所有商品均为空白身份注册，不包含公民隐私信息！本站不向未成年人提供任何服务，如您未成年，请立即关闭网站。</span
          >
        </div>
      </div>
    </div>
    <div class="product">
      <ul class="product_ul" v-if="productList.length > 0">
        <li
          v-for="(item, index) in productList"
          :key="item.id"
          class="product_li"
          :class="{ active: imgIndex == index }"
          @click="handleSelectProduct(item.id, index)"
        >
          <img :src="imgList[index]" alt="" />
          <span>{{ item.name }}</span>
        </li>
      </ul>
    </div>

    <div class="detail">
      <div class="detail_left">
        <img :src="imgList[imgIndex]" alt="" />
        <div class="img_bottom">24小时自动发货</div>
      </div>
      <div class="detail_right" v-if="productListChildren.length > 0">
        <h4>{{ productListChildren[specificIndex].name }}</h4>
        <div class="right_content">
          <div style="font-size: 12px; font-weight: 700; padding-top: 3px">
            近30天已售
            <span style="color: red">{{
              imgIndex == 0
                ? "9651"
                : imgIndex == 1
                ? "5941"
                : imgIndex == 2
                ? "4973"
                : "2791"
            }}</span>
            件
          </div>
          <div style="display: flex; align-items: center; padding-right: 5px">
            <span style="font-weight: 700; font-size: 12px"> 购买数量： </span>
            <span
              class="right_btn"
              @click="buyNubmer > 1 ? buyNubmer-- : null"
              style="padding-top: -5px"
            >
              -
            </span>
            <span style="margin: 0 8px">{{ buyNubmer }}</span>
            <span
              class="right_btn"
              @click="handleBuyNumber"
              style="background-color: #409eff; color: white"
              >＋</span
            >
          </div>
        </div>
        <div class="right_hint">
          <span>账号独享</span>
          <span>自动发货</span>
          <span>错误包换</span>
          <span>24H售后</span>
        </div>
        <div class="right_money">¥{{ totalPrice }}</div>
        <div class="right_hint" style="margin-top: 4px">
          <span>2件8折</span>
          <span>4件及以上7折</span>
          <span>好评率超98%</span>
        </div>
      </div>
    </div>
    <div class="batch_buy">
      批量购买：
      <span @click="handleBatchBuy(3)">3件</span>
      <span @click="handleBatchBuy(5)">5件</span>
      <span @click="handleBatchBuy(10)">10件</span>
      <span @click="handleBatchBuy(20)">20件</span>
    </div>
    <div class="specification" v-if="productListChildren.length > 0">
      <div style="display: flex;justify-content: space-between;margin: 10px; font-size: 16px">
        <h4 >规格</h4>
        <el-button size="mini" type="success" v-show="imgIndex==0" @click="openTwitter"
          >打开Twitter App</el-button
        >
      </div>
      <div>
        <ul class="specification_ul">
          <li
            class="specification_li"
            v-for="(item, index) in productListChildren"
            :class="{ active: specificIndex === index }"
            :key="index"
            @click="handleSelectPrice(index)"
          >
            <div class="hint">{{ item.memo }}</div>
            <div
              style="
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              "
            >
              {{ item.name }}
            </div>
            <div style="color: red">¥{{ item.price }}</div>
          </li>
          <li
            v-show="imgIndex == 0"
            class="specification_li"
            @click="handleCustomization()"
          >
            <div class="hint">联系客服</div>
            <div>定制账号</div>
            <!-- <div style="color: red">¥{{ item.price }}</div> -->
          </li>
          <li
            v-show="imgIndex == 5"
            class="specification_li"
            @click="handleCustomization()"
          >
            <div class="hint">联系客服</div>
            <div>粉丝号</div>
            <!-- <div style="color: red">¥{{ item.price }}</div> -->
          </li>
        </ul>
      </div>
    </div>
    <div class="details">
      <div class="details_left">详情</div>
      <div class="details_right" @click="handleOpenComment">
        商品评论(7624)<i class="el-icon-caret-right"></i>
      </div>
    </div>
    <div class="commodity" v-show="imgIndex == 0">
      <!-- <h4 style="font-size: 16px">商品详情</h4> -->
      <div v-if="specificIndex == 0">
        <span>您需要自行准备网络相关的软件，客服无法帮助您。</span>
        <p>
          <span>7月推特进行重大安全性更新，使用免费加速器会封号（99％）</span>
        </p>
        <h5>账户详细资料:</h5>
        <p>✅登录简单</p>
        <p>✅创建于：2024年</p>
        <p>✅长期售后，出现被盗密错可永久售后</p>
        <h5>商品信息格式:</h5>
        <p>账号ID｜账号密码｜秘钥｜接码网站</p>
        <h5>⚠️账号保修政策:</h5>
        <span>365天内因任何问题导致账号封禁，则提供1换1无理由保换。</span>
        <!-- <h5>一.商品介绍</h5>
        <p>1.本商品为推特账号，账号为新号（注册时间小于1个月）。</p>
        <p>账号发货格式：账号---密码---邮箱---邮箱密码</p>
        <p>2fa密钥为 一串大写字母，如YE47BHBT2YDDPM5L，前往2fa.fun使用</p>
        <p>3.账号为正常账号，非受限账号，无虚假账密，可以直接登录使用。</p>
        <p>4.账号带头像，中文/英文昵称，大部分有简介和一条养护推文。</p> -->
      </div>
      <div v-else-if="specificIndex == 1">
        <h5>一.商品介绍</h5>
        <p>
          1.本商品为<span>美国地区</span>推特账号，账号为<span
            >解敏邮箱新号，带2FA密钥。</span
          >
        </p>
        <p>账号发货格式：账号---密码---绑定邮箱---绑定邮箱密码</p>
        <p>密保邮箱：<a href="https://mail.tm/zh/">https://mail.tm/zh/</a></p>
        <p>
          2.账号已绑定邮箱（不保证一定可用），<span>仅对登录前账号主页被限制、冻结售后，登录时出现绑定邮箱和手机验证码是环境或设备问题，无售后，请使用干净优质环境进行登录。</span>未绑定手机号，首次登录需绑定自己手机号。
        </p>
        <p>3.账号为正常账号，非受限账号，无虚假账密，可以直接登录使用。</p>
        <p>4.账号带头像，中文/英文昵称，大部分有简介和一条养护推文。</p>
      </div>
      <div v-if="specificIndex == 2">
        <h5>一，使用说明</h5>
        <p>1.本商品为<span>推特蓝V会员号(Premium+)</span></p>
        <p>2.会员有效期15天，从购买起开始计算</p>
        <h5>3.推特蓝V会员优势：</h5>
        <p>
          可以发表长文章，特别是一些4000字以上的文章，可以提供一定的流量加成，同时广告数量也会有所减少，不仅能够上传一些超长的视频内容，同时还能够上传一些高清晰度的视频，能够带来更好的推广体验
        </p>
        <p>
          4.由于官方的审核机制，订阅 Twitter Blue
          后，更改您的个人资料照片、显示名称或用户名 (@handle)
          将导致蓝色标记丢失，直到该帐户被官方验证为继续满足要求，并且在此期间将不允许进一步更改本次审核期间。
        </p>
        <span>所以请不要更改用户资料！会导致蓝V消失几天！</span>
        <h5>二.使用说明</h5>
        <p>1.请先确认是否能正常访问推特，不能正常访问肯定是没法登陆的。</p>
        <p>
          推特访问测试连接：<a href="https://twitter.com/Mike_Pence"
            >https://twitter.com/Mike_Pence</a
          >
        </p>
        <span
          >如不能正常访问测试连接，请勿购买，因不能正常访问推特造成的无法登陆不予售后。</span
        >
        <p>2.查询账号状态：https://twitter.com/+账号</p>
        <p>例如：账号为"realDonaldTrump"</p>
        <p>https://twitter.com/realDonaldTrump</p>
        <h5>3.绑定手机号方法如下图</h5>
        <div>
          <img src="@/assets/图片1.png" alt="" />
        </div>
        <div>
          <img src="@/assets/图片2.png" alt="" />
        </div>
        <p>
          登录时遇到上图这种情况,输入账号中带的邮箱就可以,注意这里是输入邮箱不需要验证码。
        </p>
        <h5>三.注意事项</h5>
        <p>
          1.付款后您可以在购买完成的页面查看所购买的账号信息，或者可以点击右上角的订单中心查看提取，<span
            >24小时内自动发货。</span
          >
        </p>
        <p>2.账号售出删档，买家自行保护账号安全，被盗我们无法找回！</p>
        <p>
          3.虚拟商品一经发货不支持退款（除账密问题外）。<span
            >请使用干净优质环境进行登录，如出现邮箱验证码，请更换线路。这种问题不是商品问题。</span
          >
        </p>
        <p>
          4.登入后不要立刻修改昵称修改头像、简介、用户名、密码和其他资料，如需修改等稳定后再修改，一般3~7天。修改资料容易造成冻结,修改资料或设置不要一次性修改，每隔1天改一项。
        </p>
        <p>5.一个环境一个设备请勿登录多个账号，容易出验证和冻结。</p>
        <p>
          6.登录后请勿急着关注、转发、点赞等操作行为，需挂号2~3天，3天后即可发推文，推文量每天不超过2篇，7天后即可正常
        </p>
        <p>7.请勿频繁登入登出账号，以及频繁更换登入设备。</p>
        <p>
          8.若需要大量批发可以联系我们的线上客服，客服会安排转人给您联系详谈价格。
        </p>
        <p>
          警告：严禁从本平台购买的账号用于非法用途，一经发现冻结余额并清退，相关信息提交给公安部门，将全力配合相关部门予以打击。
        </p>
        <p>*本售后政策不叠加【随心换服务】</p>
        <p>账号类商品：自购买日起售后期3天，商品质量问题免费更换</p>
        <p>邮箱类商品：自购买日起3天内</p>
        <p>
          卡密类商品：因卡密都是正规可囤，无售后期。（如有问题随时联系我们处理）
        </p>
        <p>充值类商品：到账后无法售后</p>
        <p>
          购买说明：推特（蓝V
          Premium+）15天会员-成品号来自官方渠道，支持极速发货、错误包换，如有需要可关注
        </p>
      </div>
    </div>
    <div class="commodity" v-show="imgIndex == 1">
      <div>
        <h5>一.商品介绍</h5>
        <p>如何登录脸书教程：</p>
        <p>1.本商品为【脸书】账号，禁止新手购买，您购买后将获得格式：</p>
        <p>
          【新FB脸书账号格式：账号----密码----主页----邮箱账号----邮箱密码----辅助邮箱】
        </p>
        <p>【新FB脸书账号格式：账号----密码----2FA】</p>
        <p>【新FB脸书账号格式：账号----密码----手机----2FA】</p>
        <p>
          【老FB脸账书号格式：账号----密码----邮箱账号----邮箱密码----FA2----辅助邮箱】格式不同
        </p>
        <p>
          例如：bambanomb@mail.ru----bbP5XUK----facebook.com/61556597594616----bambanomb@mail.ru----Evv822KN----eagennahok@hotmail.com
        </p>
        <h5>2.登录方式:</h5>
        <p>【账号密码登录后会提示要6位验证码</p>
        <p>登录邮箱账号密码】复制到需要输入验证码的地方即可登录：</p>
        <p>以下为邮箱登录地址解释例如：需要打开使用加速器节点</p>
        <p>【ineenor@bk.ru----770eYko5】登录地址： <a href="bk.ru">bk.ru</a></p>
        <p>
          【bambanomb@mail.ru----Evv822KN】登录地址：<a href="mail.ru"
            >mail.ru</a
          >
        </p>
        <p>
          【epinnity@list.ru----aVtB06Cqq7oX】登录地址：
          <a href="list.ru">list.ru</a>
        </p>
        <h5>3.【新FB脸书账号格式：</h5>
        <p>
          账号----密码----2FA】登录方式:【账号密码登录后会提示要6位验证器验证码，
          <a href="https://2fa.zone/2FA">https://2fa.zone/2FA</a>
          】复制到需要输入验证码的地方即可登录： 例如：
        </p>

        <p>
          61557935229196——Alam00——ONBJ6DAUQ36MR5QDXSFETKEOPMFXZTHD
          账号——密码——2FA
        </p>
        <p>
          <span>https://2fa.zone/ONBJ6DAUQ36MR5QDXSFETKEOPMFXZTHD</span>
          买前必看：
        </p>
        <span
          >登录账户前，浏览器清除缓存、手机APP卸载重新安装后再进行登录。</span
        >
      </div>
    </div>
    <div class="commodity" v-show="imgIndex == 2">
      <div>
        <div style="color: orange">
          尊敬的客户，我们的账号主要销售给海外华侨或者跨境电商使用，由于该账号是海外APP
          如果您在中国大陆，大陆网络可能无法直接连接，您可以尝试借助加速器网络访问。对给您带来的不便表示歉意，并感谢您的理解和支持。
        </div>
        <h5>一.商品介绍</h5>
        <p>1.本商品为Tik Tok海外登录账号，账号为独享，售出删档。</p>
        <p>2.您购买后将获得电子邮箱和密码，收到后请及时更改密码。</p>
        <p>
          3.【普通账号】注册时间3个月以下，【优质账号】注册时间为6个月以上。
        </p>
        <p>注意： 网页端可正常登录使用，app端口必须按以下操作方可登录</p>
        <p>1、必须拔掉手机SIM卡；</p>
        <p>2、加速器必须是全局代理，且时政网络不可用如香港、加拿大、印度</p>
        <p>国内软件对于检测自己人非常牛，国内ip也必须出海 网络检测 ip111.cn</p>
        <p>
          3、手机语言需要改成繁体或者English。这个也是必须的，不然百分百刷不出来。
        </p>
        <h5>二.使用说明</h5>
        <p>
          纯净ip状态下，tk是没有验证检测的，如遇到验证代码，请前往注册邮箱的邮箱品牌登录邮箱收取验证码
        </p>
        <p>
          如账号：xxxx@hotmail.com，可搜索
          <a href="hotmail.com">hotmail.com</a> 即可前往品牌邮箱网址 使用教程
          <a href="https://hao.744.cn/blog/id/9.html"
            >https://hao.744.cn/blog/id/9.html</a
          >
        </p>
        <h5>三.注意事项</h5>
        <p>
          1.付款后您可以在购买完成的页面查看所购买的账号信息，或者可以点击右上角的订单中心查看提取，24小时内自动发货。
        </p>
        <p>2.账号售出删档，买家自行保护账号安全，被盗我们无法找回！</p>
        <p>
          3.拿到账号请务必修改密码，如有其他验证方式（手机或邮箱），也请修改，若有问题直接查询百度既有答案和教程。
        </p>
        <p>
          4.若需要大量批发可以联系我们的线上客服，客服会安排转人给您联系详谈价格。
        </p>
        <p>购买说明：TikTok账号来自官方渠道，支持极速发货、错误包换</p>
      </div>
    </div>
    <div class="commodity" v-show="imgIndex == 3">
      <div>
        <h5>一.商品介绍</h5>
        <p>
          1.本商品为美国地区苹果账号，未绑定手机号您购买后将获得登录账号、密码、密保问题及答案、生日。
        </p>
        <p>2.您可通过密保问题及答案，完成修改密码、绑定邮箱、手机号等操作。</p>
        <p>
          3.可登录苹果icould，登录icould请务必关闭查找iPhone功能，请不要共享他人，账号泄露会导致照片等信息共享，他人会封锁您的设备无法使用
        </p>
        <h5>二.注意事项</h5>
        <p>
          1.付款后您可以在购买完成的页面查看所购买的账号信息，或者可以点击右上角的订单中心查看提取，24小时内自动发货。
        </p>
        <p>
          2.所售账号均已验证过，无虚假、错误账密，售出删档，买家自行保护账号安全，被盗我们无法找回！
        </p>
        <p>
          3.海外账号无法确定使用期限，请保持ID活跃度，经常修改密码。如出现官方收回停用情况，平台无法找回。（官方行为，平台不承担责任）
        </p>
        <p>
          4.拿到账号请务必修改密码，如有其他验证方式（手机、邮箱或密保），也请修改，若有问题直接百度查询答案和教程。
        </p>
        <p>5.虚拟商品一经发货不支持退款（除账密问题外）。</p>
        <p>
          6.若需要大量批发可以联系我们的线上客服，客服会安排转人给您联系详谈价格。
        </p>
        <p>购买说明：苹果ID来自官方渠道，支持极速发货、错误包换。</p>
      </div>
    </div>
    <div class="commodity" v-show="imgIndex == 4">
      <div>
        <h5>使用注意事项：</h5>
        <p>为确保账号的长期安全与稳定，请注意以下事项：</p>
        <p>避免泛滥的IP：请勿使用频繁滥用的公共IP地址进行登录或操作。</p>
        <p>降低操作频率：避免高频率操作，例如大量私信、群发消息、强制拉人等，以降低封号风险。</p>
        <p>首次登录安全：建议首次登录后，立即修改两步验证密码，增强账号的隐私保护。</p>

        <h5>账号信息：</h5>
        <p>地区：东南亚随机地区</p>
        <p>注册时间：白号注册超过30天，具备较高稳定性与安全性。</p>
        <p>验证密码：从Telegram客户端尝试登录时, Telegram 会要求您输入验证码, 可能还会有两步验证密码, 您可以在订单页查询验证码和密码, 建议登录后立即修改密码</p>
      </div>
    </div>
    <div class="commodity" v-show="imgIndex == 5">
      <div>
        <h5>一、商品介绍</h5>
        <p>1.本商品为Instagram登录账号，您购买后将获得账号、密码、绑定邮箱号、邮箱密码。</p>
        <p>2.账号已绑定临时邮箱，发货后请及时换绑邮箱，并修改密码等操作，一经发货不支持退款（除账密问题外）。</p>
        <p>3.账号各版本详细。</p>
        <p>【新手版】2fa验证登陆 带邮箱购买后自行更改，资料空白；</p>
        <p>【一年号版】 邮箱验证 带10张风景帖 已添加头像；</p>
        <p>【两年账号】 邮箱验证 带10张风景贴 粉丝95+/- 资料完整；</p>
        <p>【粉丝号】注册时间不定，带有不同数量的粉丝. 500粉120 1000粉230 1500粉290 2000粉350 需要多少联系我们线上客服 客服给您发库存充足</p>

        <h5>二、使用说明</h5>
        <p>邮箱登录地址：@后面为登录网址，复制到浏览器打开即可。</p>
        <p>1.任何Instagram账号都可能在登录或使用期间要求通过短信验证，这是正常的，可以使用自己的号码或接码平台进行确认，这种情况不进行售后处理。</p>
        <p>2.登录账号时可能会要求接收邮箱验证码，登录邮箱查看，输入即可。</p>
        <p>3.首次登录前可以通过搜索用户名的方法，查看个人主页，能显示资料的证明账号正常。</p>

        <h5>三、注意事项</h5>
        <p>1.付款后您可以在购买完成的页面查看所购买的账号信息，或者可以点击右上角的订单中心查看提取，24小时内自动发货。</p>
        <p>2.账号售出删档，买家自行保护账号安全，被盗我们无法找回！</p>
        <p>3.如遇网页版无法登录等情况，请换成使用移动端登录。</p>
        <p>4.拿到账号请务必修改密码，如有其他验证方式（手机或邮箱），也请修改，若有问题直接查询百度既有答案和教程。</p>
        <p>5.若需要大量批发可以联系我们的线上客服，客服会安排转人给您联系详谈价格。</p>
      </div>
    </div>
    <div class="footer">
      <div class="paytype">
        <div
          class="pay_left"
          :class="{ active: payType === 1 }"
          @click="payType = 1"
        >
          <div><img src="@/assets/img/ali.png" alt="" />支付宝</div>
          <span>支持花呗</span>
        </div>
        <div
          class="pay_right"
          :class="{ active: payType === 2 }"
          @click="payType = 2"
        >
          <div><img src="@/assets/img/wx.png" alt="" />微信</div>
          <span>多件折扣</span>
        </div>
      </div>
      <div class="footet_btn">
        <div class="footer_left" @click="handleKeFu">
          <img src="@/assets/img/icon-kefus.png" alt="" />在线客服
        </div>
        <div>
          合计：<span style="color: red">¥{{ totalPrice }}</span>
        </div>
        <el-button class="btn_right" @click="handleFrimOrder"
          >立即购买</el-button
        >
      </div>
    </div>
    <el-dialog
      :visible.sync="dialogVisible"
      width="90%"
      :before-close="handleClose"
      ><div slot="title" style="text-align: center; font-weight: 700">
        订单确认
      </div>
      <h3 style="color: red; font-size: 17px">
        <i class="el-icon-warning"></i
        >付款完成后，请到订单查询页面获取订单号，如有其他问题请联系客服
      </h3>
      <div class="config_header">
        <div class="header_left">
          <img :src="imgList[imgIndex]" alt="" />
        </div>
        <div class="header_right" v-if="productListChildren.length > 0">
          <div class="right_name">
            {{ productListChildren[specificIndex].name }}
          </div>
          <div class="right_content" style="font-weight: 900">
            <div style="display: flex; align-items: center; padding-right: 5px">
              <span style="font-weight: 700"> 购买数量： </span>
              <span
                class="right_btn"
                @click="buyNubmer > 1 ? buyNubmer-- : null"
                style="padding-top: -5px"
              >
                -
              </span>
              <span style="margin: 0 8px">{{ buyNubmer }}</span>
              <span
                class="right_btn"
                @click="buyNubmer++"
                style="background-color: #409eff; color: white"
                >＋</span
              >
            </div>
          </div>
          <div class="right_money">¥{{ totalPrice }}</div>
        </div>
      </div>
      <div class="batch_buy">
        批量购买：
        <span @click="handleBatchBuy(3)">3件</span>
        <span @click="handleBatchBuy(5)">5件</span>
        <span @click="handleBatchBuy(10)">10件</span>
        <span @click="handleBatchBuy(20)">20件</span>
      </div>
      <div class="config_sale">
        <div class="sale_left">优惠搭配</div>
        <div>
          <span>2件8折</span>
          <span>4件以上7折</span>
        </div>
      </div>
      <div style="display: flex; align-items: center">
        <span style="display: inline-block; width: 140px">邮箱(可选填)：</span
        ><el-input v-model.trim="mailbox" placeholder="请输入"></el-input>
      </div>
      <div style="color: orange">
        <i class="el-icon-warning"></i>
        支付完成会有自动跳转商品发货页面，输入邮箱付款成功后会自动把订单号发送至邮箱
      </div>
      <div class="config_footer">
        <div style="font-size: 18px; font-weight: 600; margin: 5px 0">
          合计：<span style="color: red">¥{{ totalPrice }}</span>
        </div>
        <div>
          <el-button
            @click="handleConfigSubmit"
            :loading="configLoading"
            class="config_btn"
            >立即支付</el-button
          >
        </div>
      </div>
    </el-dialog>
    <ProductComment v-if="reviewDrawer" :commentDrawer.sync="reviewDrawer" />
  </div>
</template>

<script>
import HeaderComponent from "../components/HeaderComponent.vue";
import axios from "axios";
import dayjs from "dayjs";
import ProductComment from "../components/ProductComment.vue";
// import { MD5 } from "crypto-js";
export default {
  name: "HelloWorld",
  data() {
    return {
      imgIndex: 0,
      reviewDrawer: false,
      mailbox: "",
      imgList: [
        require("@/assets/img/photo_2024-08-18_14-33-31.jpg"),
        require("@/assets/img/59f41fa8-c4ab-4522-93c7-35c89d695cf0.png"),
        require("@/assets/img/019bed5a61cec8a8012113c73d3979.jpg@2o.jpg"),
        require("@/assets/img/5c4288a83ee26_610.jpg"),
        require("@/assets/512px-Telegram_logo.svg.png"),
        require("@/assets/ins.webp"),
      ],
      timer: null,
      device: "",
      configLoading: false,
      dialogVisible: false,
      payType: 1,
      specificIndex: 0,
      buyNubmer: 1,
      productIndex: 1,
      productList: [],
      productListChildren: [],
      timeIndex: 0,
    };
  },
  components: {
    HeaderComponent,
    ProductComment,
  },
  mounted() {
    this.getGoodsMet();
    const id = this.$route.query.order_id;
    console.log("id---", id);
    if (id) {
      // this.orderNumbere = id;
      // this.handleOrderInquiry(id);
      // this.handleTimeFun(id);
      // this.handleCopyID(id)
      this.$router.push({
        name: "OrderSearch",
        query: { order_id: id },
      });
    }
  },
  methods: {
       openTwitter(){
   // Twitter的URL Scheme
      var appScheme = "twitter://user?screen_name=twitter";
      var timeout;
      // 尝试打开Twitter App
      window.location = appScheme;
      // 设置超时，检测是否打开了APP
      timeout = setTimeout(function() {
        // 如果Twitter App没有响应，则跳转到Twitter网页版
        window.location = "https://twitter.com/twitter";
      }, 1500); // 1.5秒后检查是否唤醒App
      console.log('timeout',timeout);
    },
    handleOpenComment() {
      console.log("88");
      this.reviewDrawer = true;
    },
    handleCustomization() {
      window.location.href = "https://dp.ddkjw.cc/kefu/66992ffd7d489";
    },
    handleClick() {
      const existingData = localStorage.getItem("oderIdList");
      // 解析现有数据，如果没有数据则初始化为空数组
      let oderIdList = existingData ? JSON.parse(existingData) : [];
      // 新要添加的值
      let obj = {};
      const now = dayjs();
      console.log(now.format("YYYY-MM-DD HH:mm:ss"));
      obj.time = now.format("YYYY-MM-DD HH:mm:ss");
      obj.orderNum = "acc23568561265565";
      // 将新值添加到数组中
      oderIdList.unshift(obj);
      // 将更新后的数组存储回 localStorage
      localStorage.setItem("oderIdList", JSON.stringify(oderIdList));
    },
    handleBuyNumber() {
      if (this.buyNubmer >= this.productListChildren[this.specificIndex].count)
        return this.$message.warning("库存不足");
      this.buyNubmer++;
    },
    handleKeFu() {
      window.location.href = "https://dp.ddkjw.cc/kefu/66992ffd7d489";
    },
    getGoodsMet() {
      axios
        .get(
          "/v1/goods/goods_met",
          {},
          {
            "Content-Type": "application/json",
          }
        )
        .then((res) => {
          const { code, data, msg, success } = res.data;
          if ((code === 200) & success) {
            console.log("data==", res.data);
            this.productList = data;
            // this.productList.push(
            //   { name: "苹果账号" },
            //   { name: "facebook账号" },
            //   { name: "tiktok账号" }
            // );
            console.log("this.productList", this.productList);
          } else {
            this.$message.error(msg);
          }
        });
    },
    getGoodsMetChildren() {
      axios
        .get(
          "/v1/goods/goods_sku_met",
          {
            params: {
              goods_id: this.productIndex,
            },
          },
          {
            "Content-Type": "application/json",
          }
        )
        .then((res) => {
          const { code, data, msg, success } = res.data;
          if ((code === 200) & success) {
            console.log("data///", res.data);
            this.productListChildren = data;
            console.log("this.productListChildren", this.productListChildren);
          } else {
            this.$message.error(msg);
          }
        });
    },
    //提交支付
    handleConfigSubmit() {
      const userAgent = navigator.userAgent.toLowerCase();
      if (userAgent.includes("micromessenger")) {
        this.device = "wechat";
      } else if (
        userAgent.includes("mobile") ||
        userAgent.includes("iphone") ||
        userAgent.includes("android")
      ) {
        this.device = "mobile";
      } else if (userAgent.includes("mqqbrowser")) {
        this.device = "qq";
      } else {
        this.device = "pc";
      }
      console.log("this.device", this.device);

      let data = {
        name: this.productListChildren[this.specificIndex].name,
        money: this.totalPrice,
        // money: this.totalPrice,
        emails: this.mailbox,
        // money: '1.00',
        // pay_type: this.payType == 1 ? "alipay" : "wxpay",
        pay_type: this.payType == 1 ? "555" : "5555",
        device: this.device,
        goods_sku_id: this.productListChildren[this.specificIndex].id,
        num: this.buyNubmer,
        memo: "test",
      };
      console.log("data-----", data);
      this.configLoading = true;
      try {
        axios.post("/v1/orders/order_pay", data).then((res) => {
          console.log("请求支付返回res", res.data);
          const { code, msg, data } = res.data;
          if (code == 200) {
            if (data.pay_url) {
              console.log("datappp", data);
              this.handleCopyInfot(data.order_id);
              window.location.href = data.pay_url;
              // this.handleTimeFun(data.order_id);
              const existingData = localStorage.getItem("oderIdList");
              // 解析现有数据，如果没有数据则初始化为空数组
              let oderIdList = existingData ? JSON.parse(existingData) : [];
              // 新要添加的值
              let obj = {};
              const now = dayjs();
              obj.time = now.format("YYYY-MM-DD HH:mm:ss");
              obj.orderNum = data.order_id;
              // 将新值添加到数组中
              oderIdList.unshift(obj);
              // 将更新后的数组存储回 localStorage
              localStorage.setItem("oderIdList", JSON.stringify(oderIdList));
              setTimeout(() => {
                this.handleCopyInfot(data.order_id);
                this.$router.push({
                  name: "OrderSearch",
                  query: { order_id: data.order_id },
                });
              }, 500);
            } else if (data.qrcode) {
              console.log("data.qrcode", data);
            } else {
              console.log("data--", data);
            }
          } else {
            this.$message.error(msg);
          }
          this.configLoading = false;
          this.dialogVisible = false;
        });
      } catch (err) {
        this.$message.error("支付通道异常，请更换支付方式");
        this.configLoading = false;
      }
    },
    handleTimeFun(id) {
      clearInterval(this.timer);
      this.timer = setInterval(() => {
        console.log("定时器", this.timeIndex);
        this.timeIndex++;
        axios
          .get("/v1/orders/order_pay_status", { params: { order_id: id } })
          .then((result) => {
            const { code, success, data } = result.data;
            if (code == 200 && success) {
              if (data.status) {
                clearInterval(this.timer);
                this.$router.push({
                  name: "OrderSearch",
                  query: { order_id: id },
                });
              }
            }
            if (this.timeIndex > 1000) {
              clearInterval(this.timer);
            }
          });
      }, 1500);
    },
    handleCopyInfot(val) {
      const textarea = document.createElement("textarea");
      textarea.value = val; // 获取span中的文本
      document.body.appendChild(textarea);
      textarea.select();
      textarea.setSelectionRange(0, textarea.value.length); // 对于移动设备
      try {
        const successful = document.execCommand("copy");
        if (successful) {
          console.log("已复制", successful);
          // this.$message.success("已复制"); // 使用 Element UI 的提示
        } else {
          // this.$message.error("复制失败");
          console.log("复制失败");
        }
      } catch (err) {
        this.$message.error("复制失败: " + err);
      }
      // 移除临时的textarea元素
      document.body.removeChild(textarea);
    },
    handleClose() {
      this.dialogVisible = false;
      this.configLoading = false;
    },
    handleFrimOrder() {
      if (this.productListChildren[this.specificIndex].count == 0)
        return this.$message.warning("库存不足");
      if (this.buyNubmer > this.productListChildren[this.specificIndex].count) {
        return this.$message.warning("库存不足");
      }
      this.dialogVisible = true;
    },

    handleOrderInquiry() {},
    handleSelectProduct(index, num) {
      this.imgIndex = num;
      this.productIndex = index;
    },
    handleBatchBuy(num) {
      this.buyNubmer = num;
    },
    handleSelectPrice(num) {
      this.specificIndex = num;
    },
  },
  computed: {
    totalPrice() {
      // return 0;
      if (this.productListChildren.length < 1) return "";
      let number =
        this.productListChildren[this.specificIndex].price * this.buyNubmer;
      let newNumber = 0;
      if (this.buyNubmer == 2 || this.buyNubmer == 3) {
        newNumber = number * 0.8;
      } else if (this.buyNubmer > 3) {
        newNumber = number * 0.7;
      } else {
        newNumber = number;
      }
      return newNumber.toFixed(2);
    },
  },
  watch: {
    productIndex: {
      handler() {
        this.specificIndex = 0;
        this.$nextTick(() => {
          this.getGoodsMetChildren();
        });
      },
      immediate: true,
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #c8d1f3c5;
  padding: 8px 0;
  padding-left: 4px;
  border-radius: 5px;
  margin: 5px 0;
  .details_left {
    font-size: 18px;
    font-weight: 900;
  }
  .details_right {
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
  }
}
.scrolling-wrapper {
  background-color: #fefbe8;
  width: 100%; /* 容器宽度 */
  overflow: hidden; /* 隐藏超出容器的内容 */
  white-space: nowrap; /* 保持内容在一行内 */
  position: relative; /* 相对定位，用于绝对定位的子元素 */
}

.scrolling-text {
  display: inline-block; /* 使得文本横向排列 */
  padding-left: 10%; /* 在开始位置留出空间 */
  animation: scroll-left 10s linear infinite; /* 应用动画 */
}

@keyframes scroll-left {
  0% {
    transform: translateX(100%); /* 从右侧开始 */
  }
  100% {
    transform: translateX(-100%); /* 滚动到左侧 */
  }
}
@media screen and (max-width: 380px) {
  .product_li {
    width: 31% !important;
  }
}
::v-deep .el-dialog__body {
  padding-top: 0;
  padding-bottom: 78px;
}
.product {
  background-color: rgba(238, 255, 2, 0.774);
  background-color: #ebebed8e;
  padding-top: 3px;
  .product_ul {
    // padding: 5px;
    // margin: 5px 0;
    margin-bottom: 5px;
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-between;
    align-items: center;
    .product_li {
      width: 31% !important;
      overflow: hidden;
      // height: 30px;
      margin-right: 7px;
      font-size: 12px;
      line-height: 35px;
      font-weight: 700;
      padding: 0px 3px;
      overflow: hidden;
      border-radius: 5px;
      background-color: #fff;
      margin-bottom: 3px;
      span {
        overflow: hidden;
      }
      img {
        width: 25px;
        height: 25px;
        border-radius: 5px;
        vertical-align: middle;
        margin-right: 4px;
      }
    }
    .active {
      background-color: #0634e92f;
      color: rgba(0, 0, 255, 0.651);
    }
  }
}
.warning_sty {
  width: 100%;
  height: 32px;
  background-color: #fefbe8;
  font-size: 16px;
  padding-left: 15px;
  color: rgb(255, 166, 0);
  vertical-align: middle;
  line-height: 32px;
  .el-icon-warning {
    color: rgb(255, 166, 0);
    margin-top: -3px;
    font-size: 17px;
    vertical-align: middle;
  }
}
.detail {
  width: 100%;
  height: 145px;
  background-color: #ebebed8e;
  display: flex;
  padding-top: 10px;

  .detail_left {
    width: 30%;
    height: 100%;
    img {
      width: 100%;
      height: 110px;
    }
    .img_bottom {
      font-size: 14px;
      background-color: green;
      color: white;
      border-radius: 3px;
    }
  }
  .detail_right {
    padding-left: 5px;
    flex: 1;
    height: 100%;
    background-color: rgba(157, 168, 157, 0.123);
    .right_content {
      display: flex;
      font-size: 14px;
      margin: 3px 0;
      justify-content: space-between;
      .right_btn {
        text-align: center;
        cursor: pointer;
        line-height: 16px;
        display: inline-block;
        height: 20px;
        width: 20px;
        color: #bdc0dd;
        border-radius: 12.5px;
        border: 1px solid #bdc0dda1;
      }
    }
    .right_hint {
      span {
        font-size: 12px;
        border: 1px solid #b65151;
        padding: 1px 3px;
        border-radius: 2px;
        color: #b65151;
        margin-right: 3px;
      }
    }
  }
}
.batch_buy {
  width: 100%;
  height: 40px;
  line-height: 40px;
  margin-top: 8px;
  background-color: #fefbe8;
  padding-left: 5px;
  span {
    font-size: 14px;
    font-weight: 600;
    padding: 2px 4px;
    border: 1px solid #ccc;
    border-radius: 3px;
    margin-right: 5px;
    cursor: pointer;
  }
}
.specification {
  font-size: 14px;
  .specification_ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .specification_li {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;

      font-size: 12px;
      font-weight: 700;
      padding: 0 5px;
      height: 33px;
      line-height: 33px;
      border-radius: 5px;
      width: 49%;
      border: 1px solid #9b8d8dbd;
      position: relative;
      .hint {
        position: absolute;
        left: 5px;

        height: 16px;
        top: -8px;
        line-height: 16px;
        background-color: red;
        border-radius: 2px;
        color: white;
        padding: 0 3px;
        font-size: 12px;
      }
    }
    .active {
      background-color: #5b9cf8;
      color: white;
    }
  }
}
.commodity {
  font-size: 12px;
  padding-bottom: 110px;
  span {
    color: rgb(197, 41, 41);
  }
  h4 {
    margin-bottom: 5px;
  }
  h5 {
    font-size: 14px;
  }
  p {
    margin: 5px;
  }
  img {
    object-fit: contain;
    width: 90%;
    height: 350px;
  }
}
.footer {
  position: fixed;
  width: 100%;
  height: 110px;
  padding: 5px 0;
  background-color: #fefbe8;
  left: 0;
  bottom: 0;
  .paytype {
    height: 40%;
    display: flex;
    padding: 0 10px;
    font-size: 14px;
    justify-content: space-between;
    .pay_left,
    .pay_right {
      padding: 3px 5px;
      height: 40px;
      width: 48%;
      display: flex;
      justify-content: space-between;
      border-radius: 3px;
      align-items: center;
      border: 1px solid rgb(184, 158, 112);
    }
    img {
      width: 33px;
      height: 33px;
      vertical-align: middle;
      margin-right: 5px;
    }
    span {
      display: inline-block;
      height: 16px;
      padding: 1px 4px;
      border-radius: 2px;
      line-height: 16px;
      color: white;
      background-color: red;
      right: 0;
    }
  }
  .active {
    border: 2px solid rgb(192, 24, 24) !important
    ;
  }
  .footet_btn {
    margin-top: 11px;
    padding: 0 5px;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .footer_left {
      cursor: pointer;
      color: green;
      img {
        width: 40px;
        height: 40px;
        vertical-align: middle;
      }
    }
    .btn_right {
      font-weight: 700;
      margin-right: 5px;
      color: white;
      background: linear-gradient(90deg, #ff9600, #ff6700);
    }
  }
}
.config_header {
  display: flex;
  height: 100px;
  .header_left {
    flex: 1;
    // background-color: green;
    height: 100px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .header_right {
    flex: 2;
    margin-left: 10px !important;
    width: 100%;
    font-size: 14px;
    font-weight: 900 !important;
    margin: 3px 0;
    .right_btn {
      text-align: center;
      cursor: pointer;
      line-height: 16px;
      display: inline-block;
      height: 20px;
      width: 20px;
      color: #bdc0dd;
      border-radius: 12.5px;
      border: 1px solid #bdc0dda1;
    }
  }
}
.right_money {
  margin: 10px 0;
  color: red;
  font-weight: 600;
}

.config_sale {
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  .sale_left {
    font-size: 18px;
    font-weight: 500;
    color: red;
  }
  span {
    border: 1px solid rgb(120, 207, 120);
    margin-right: 2px;
    color: rgb(120, 207, 120);
  }
}
.config_btn {
  color: white;
  padding: 20px 85px;
  border-radius: 36px;
  background: linear-gradient(90deg, #ff9600, #ff6700);
}
.config_footer {
  width: 100px;
  height: 40px;

  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}
.right_name {
  font-weight: 800 !important;
  font-size: 20px;
  margin-bottom: 6px;
}
.scroll-container {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
}

.scroll-text {
  display: inline-block;
  white-space: nowrap;
  padding-left: 100%;
  animation: scroll-left 30s linear infinite;
}

/* 动画效果 */
@keyframes scroll-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
</style>
